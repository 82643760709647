import React, { useState, useEffect, useRef } from 'react';
import {
    useParams
  } from "react-router-dom";
//material-ui
import { makeStyles } from '@material-ui/core/styles';
//material-table
import MaterialTable, { MTableToolbar } from 'material-table';
import Typography from '@material-ui/core/Typography';
//input select 
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
//input text
import TextField from '@material-ui/core/TextField';
//Icons
import AddCircleRounded from '@material-ui/icons/AddCircleRounded';
import Edit from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
//query
import serviceApi from '../services/ServiceApi';
import assocTypeIndicateurApi from "../services/AssocUserTypeIndicateurApi";
import userApi from '../services/UserApi';
//second table
import ObjectifTable from './objectifSousTab';
//jwt token
import jwtDecode from "jwt-decode";
//styling
import * as styling from '../services/styling';
//alert 
import { Alert } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';


const useStyles = makeStyles(theme => ({
    root: {
        //marginTop: '15vh',
        paddingLeft: '30px',
        height: "90px"
        //remplace with breakpoint
    },
    table: {
        marginLeft: '30px',
        marginRight: '30px',
    },
    selectFormControl: {
        margin: theme.spacing(1),
        minWidth: "100%",

    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,

    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    inputForm: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    title: {
        backgroundColor: styling.tableHeaderBackgroundColor,
        color: styling.tableHeaderColor,
        height: "5vh",
        textTransform: "uppercase",
    },
    subtitle: {
        backgroundColor: styling.tableHeaderBackgroundColor,
        color: styling.tableHeaderColor,
        textTransform: "uppercase",
    },
    toolbar:{
        backgroundColor: styling.backGroundHeader,
        
    },
    titleText : {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop:'25px',
        color : styling.colorTextTitle,
        textTransform: "uppercase"
    }
}));

const Objectifs = () => {

    const classes = useStyles();
    //modal alert
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    //isLoading
    const [chargement, setChargement] = useState({
        isLoading: false
    });
    //table ref
    let tableRef = useRef()

    //error
    const [openAlert, setOpenAlert] = useState(false);
    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    //select catégorie & sous catégorie 
    const [categorie, setCategorie] = useState([]);
    const [sousCategorie, setSousCategorie] = useState([]);
    //data categorie
    useEffect(() => {
        const fetchCategorie = async () => {
            try {
                const dataCategorie = await serviceApi.findAllCategorie();
                let CategorieList = [];
                dataCategorie.forEach(element => {
                    CategorieList.push({
                        value: element.id,
                        name: element.libelleCategorie,
                        id: element.id
                    });
                });
                let paramIndicId = null;
                let typeIndicIdCategorieArray = [];
                let FilteredCategorie = [];
                if( getDecodedToken().roles[0]  === 'USER'){
                    // get id 
                    const responseAssoc = await assocTypeIndicateurApi.sortByTypeIndicateurUsername(paramIndicId,getDecodedToken().username);
                    responseAssoc.forEach(element => {                        
                        typeIndicIdCategorieArray.push(element.idtypeindicateur.idSousCategorie.idCategorie.id)
                    });
                    let sortedUniqueId = [...new Set(typeIndicIdCategorieArray)];
                    for(let i = 0; i < CategorieList.length; i++){                        
                            sortedUniqueId.forEach(element =>{
                                if(element === CategorieList[i].id){
                                    FilteredCategorie.push(CategorieList[i])
                                }
                            })
                    }
                    setCategorie(FilteredCategorie)
                }else{
                    setCategorie(CategorieList)
                }
            }
            catch (err) {
                console.log(err)
            }
        }
        fetchCategorie();
    }, []);
    //data sous categorie
    useEffect(() => {
        const fetchSousCategorie = async () => {
            try {
                const dataSousCategorie = await serviceApi.findAllSousCategorie();
                let SousCategorieList = [];
                dataSousCategorie.forEach(element => {
                    SousCategorieList.push({
                        value: element.id,
                        name: element.libelleSousCategorie,
                        id_categorie: element.idCategorie,
                        id: element.id
                    });
                });
                setSousCategorie(SousCategorieList)
            }
            catch (err) {
                console.log(err)
            }
        };
        fetchSousCategorie();
    }, []);

    //onchange input select  catégorie / sousCategorie
    const [selectsValues, setSelectsValues] = useState({
        selectCategorie: null,
        selectCategorieName: null,
        selectSousCategorie: null,
        selectSousCategorieName: null,
    })

    //add role

    const getDecodedToken = () => {
        const tokenBrut = window.localStorage.getItem("authToken");
        if(tokenBrut){
            const decodedToken = jwtDecode(tokenBrut);       
            return decodedToken;
        }
    }

    
    const addRole = async (data) => {
        try {
            //animation            
            setChargement({
                isLoading: true
            });
            // get user
            const decodedToken = getDecodedToken();
            
            //filtering access
            const allAssocUser = await assocTypeIndicateurApi.findAllAssocUserTypeIndicteur();
            const userList = await userApi.findAllUsers();
            
            let filteredAccesses = [];
            let filterAssoc = [];
            //remplace uri by object
            allAssocUser.forEach(association =>{
                let id = parseInt(association.iduser.match(/\/(\d+)+[\/]?/g).map(id => id.replace(/\//g, '')));
                userList.forEach(user => {
                    if(id === user.id ){
                        association.iduser = user;
                        if(association.iduser.email === decodedToken.username ){
                            filterAssoc.push(association);
                        }
                    }
                })
            })
            
            data.forEach(element => {
                filterAssoc.forEach(association => {
                    if (element.indicId === association.idtypeindicateur.id) {
                        switch (decodedToken.roles[0]) {
                            case 'SUPER_ADMIN':
                                element.access = {
                                    role: decodedToken.roles[0],
                                    lecture: true,
                                    ecriture: true,
                                    suppression: true,
                                }
                                filteredAccesses.push(element)
                                break;
                            case 'ADMIN':
                                element.access = {
                                    role: decodedToken.roles[0],
                                    lecture: true,
                                    ecriture: true,
                                    suppression: true,
                                }
                                filteredAccesses.push(element)
                                break;
                            case 'SUPER_USER':
                                if (association.lecture === true) {

                                    element.access = {
                                        role: decodedToken.roles[0],
                                        lecture: association.lecture,
                                        ecriture: true,
                                        suppression: true,
                                    }
                                    filteredAccesses.push(element)
                                }
                                break;
                            case 'USER':
                                if (association.lecture === true) {
                                    element.access = {
                                        role: decodedToken.roles[0],
                                        lecture: association.lecture,
                                        ecriture: false,
                                        suppression: false,
                                    }
                                    element.accessUser = {
                                        role: decodedToken.roles[0],
                                        lecture: association.lecture,
                                        ecriture: association.ecriture,
                                        suppression: association.suppression,
                                    }
                                    filteredAccesses.push(element)
                                }
                                break;
                            default:    
                                element.access = {
                                    role: decodedToken.roles[0],
                                    lecture: false,
                                    ecriture: false,
                                    suppression: false,
                                }
                        }
                        
                    }
                })
            })
            if (decodedToken.roles[0] === 'SUPER_ADMIN') {
                data.forEach(element => {
                    element.access = {
                        role: decodedToken.roles[0],
                        lecture: true,
                        ecriture: true,
                        suppression: true,
                    }
                })
                setEntries(data)
            }
            else { 
                setAllObjectifs(filteredAccesses);// remplace by sorted data
                setEntries(filteredAccesses);// remplace by sorted data
            }

            
            //animation            
            setChargement({
                isLoading: false
            });

        }catch(err){
            console.log(err)
        }
        //animation            
        setChargement({
            isLoading: false
        });
    }
    // sort entries by catégorie, sous catégorie 
    const sortEntries = async (param) => {
        try {
            //animation
            setChargement({
                isLoading: true
            });
            const filtredTypeObjectif = await serviceApi.sortTypeObjectifs(param);
            let dataTabObjectifs = [];
            filtredTypeObjectif.forEach(element => {
                if (undefined === element.typeDate) {
                    element.typeDate = {};
                    element.typeDate.libelle = "";
                }
                if (undefined === element.typeUnite) {
                    element.typeUnite = {};
                    element.typeDate.libelleUnite = "";
                }
                dataTabObjectifs.push({
                    typeObjectifId: element.id,
                    libelle: element.libelle,
                    typeDate: element.typeDate.libelle,
                    typeUnite: element.typeUnite.libelleUnite,
                    commentaire: element.commentaire,
                    indic: element.idTypeIndicateur.libelle,
                    indicId: element.idTypeIndicateur.id,
                    categorie: element.idTypeIndicateur.idSousCategorie.idCategorie.libelleCategorie,
                    sousCategorie: element.idTypeIndicateur.idSousCategorie.libelleSousCategorie
                });
            })
            addRole(dataTabObjectifs);
            //animation
            setChargement({
                isLoading: false
            });
        } catch (error) {
            console.log(error)
        }
    }

    const sortSelectSousCategorie = async (idCategorie) => {
        try {
            const dataNewSousCategorie = await serviceApi.sortNewSousCategorie(idCategorie);
            let sortSousCategorieList = [];
            dataNewSousCategorie.forEach(element => {
                sortSousCategorieList.push({
                    value: element.id,
                    name: element.libelleSousCategorie,
                    id_categorie: element.idCategorie,
                    id: element.id
                });
            });
            setSousCategorie(sortSousCategorieList)

        } catch (error) {

        }
    };
    const handleChangeCategorie = (event) => {
        event.preventDefault();
        const dataValue = event.currentTarget.getAttribute("data-value");
        const dataName = event.currentTarget.getAttribute("name");
        sortSelectSousCategorie(dataValue);
        setSelectsValues({
            selectCategorie: dataValue,
            selectCategorieName: dataName,
            selectSousCategorie: selectsValues.selectSousCategorie ? selectsValues.selectSousCategorie : null,
            selectSousCategorieName: selectsValues.selectSousCategorieName ? selectsValues.selectSousCategorieName : null,
        });
        const sortByCat = {
            selectCategorie: dataValue,
            selectCategorieName: dataName,
            selectSousCategorie: selectsValues.selectSousCategorie ? selectsValues.selectSousCategorie : null,
            selectSousCategorieName: selectsValues.selectSousCategorieName ? selectsValues.selectSousCategorieName : null,
        }
        // sorting
        sortEntries(sortByCat);
    };
    const handleChangeSousCategorie = (event) => {
        const dataValue = event.currentTarget.getAttribute("data-value");
        const dataName = event.currentTarget.getAttribute("name");
        setSelectsValues({
            selectCategorie: selectsValues.selectCategorie ? selectsValues.selectCategorie : null,
            selectCategorieName: selectsValues.selectCategorieName ? selectsValues.selectCategorieName : null,
            selectSousCategorie: dataValue,
            selectSousCategorieName: dataName,
        })
        //sorting
        const sortBySousCat = {
            selectCategorie: selectsValues.selectCategorie ? selectsValues.selectCategorie : null,
            selectCategorieName: selectsValues.selectCategorieName ? selectsValues.selectCategorieName : null,
            selectSousCategorie: dataValue,
            selectSousCategorieName: dataName,
        };
        sortEntries(sortBySousCat);
    };


    //data entries
    const [entries, setEntries] = useState([]);
    //title second table
    const [titleSecondTable, setTitleSecondTable] = useState("");
    const [subtitleSecondTable, setSubtitleSecondTable] = useState("");
    //input text error
    const [nameError, setNameError] = React.useState({
        error: false,
        label: "",
        helperText: "",
        validateInput: false,
    });

    //select frequences
    const FrequenceOptions = {};
    let datesList = [];
    useEffect(() => {
        const fetchDates = async () => {
            try {
                const dataFrequence = await serviceApi.findAllDates(); //query


                dataFrequence.forEach(element => {
                    datesList.push({
                        id: element.id,
                        typeDate: element.libelle,
                    });
                });
                datesList.map(dates => {
                    const { id, typeDate } = dates;
                    FrequenceOptions[typeDate] = typeDate;
                })

            }
            catch (err) {
                console.log(err)
            }
        };
        fetchDates();
    }, []);
    //select Unité
    const UniteOptions = {};
    let uniteList = [];
    useEffect(() => {
        const fetchUnites = async () => {
            try {
                const dataUnite = await serviceApi.findAllUnites(); //query
                dataUnite.forEach(element => {
                    uniteList.push({
                        id: element.id,
                        typeUnite: element.libelleUnite,
                    });
                });
                uniteList.map(unite => {
                    const { id, typeUnite } = unite;
                    UniteOptions[typeUnite] = typeUnite;
                })
            }
            catch (err) {
                console.log(err)
            }
        };
        fetchUnites();
    }, []);
    //get url param
    const { idCategorie, idSousCategorie} = useParams();

    //Type objectifs
    const [allObjectifs, setAllObjectifs] = useState({});
    const [allIndicateurs, setAllIndicateurs] = useState({});
    const [refreshTab, setRefreshTab] = useState(false);
    const [focusThemes,setFocusThemes] = useState('');
    const [focusSousThemes,setFocusSousThemes] = useState('');
    const [refreshSelect, setRefreshSelect] = useState(false);
    

    useEffect(() => {
        if((undefined !== idCategorie) && (undefined !== idSousCategorie)){
            setFocusThemes(idCategorie);
            setFocusSousThemes(idSousCategorie);
        }else{
            setFocusThemes('_all');
            setFocusSousThemes('_all');
        }
        setRefreshSelect(!refreshSelect)
    },[]);

    useEffect(() => {
        const fetchObjectifs = async () => {
            setChargement({
                isLoading: true
            });
            const dataObjectifs = await serviceApi.findAllTypeObjectifs(); //query
            const dataIndicateurs = await serviceApi.findAllTypeIndicateurs();//query
            //save the data 
            setAllIndicateurs(dataIndicateurs);

            let dataTabObjectifs = [];
            dataObjectifs.forEach(element => {
                if (undefined === element.typeDate) {
                    element.typeDate = {};
                    element.typeDate.libelle = "";
                }
                if (undefined === element.typeUnite) {
                    element.typeUnite = {};
                    element.typeDate.libelleUnite = "";
                }
                dataTabObjectifs.push({
                    typeObjectifId: element.id,
                    libelle: element.libelle,
                    typeDate: element.typeDate.libelle,
                    typeUnite: element.typeUnite.libelleUnite,
                    commentaire: element.commentaire,
                    indic: element.idTypeIndicateur.libelle,
                    indicId: element.idTypeIndicateur.id,
                    categorie: element.idTypeIndicateur.idSousCategorie.idCategorie.libelleCategorie,
                    sousCategorie: element.idTypeIndicateur.idSousCategorie.libelleSousCategorie
                });
            })

            addRole(dataTabObjectifs);
            
            //animation
            setChargement({
                isLoading: false
            });
        }
        if((undefined !== idCategorie) && undefined !== idSousCategorie){
            setChargement({
                isLoading: true
            });
            setFocusThemes(idCategorie);
            setFocusSousThemes(idSousCategorie);
            sortEntries({
                selectCategorie: idCategorie,
                selectSousCategorie : idSousCategorie
            });
        }else{
            fetchObjectifs();
        }
    }, [refreshTab,idCategorie,idSousCategorie]);
    //table column definition\\
    const [state, setState] = useState({
        columns: [
            {
                title: "Rappel de l'indicateur concerné", field: 'indic', editable: 'never', width: "20%",
                headerStyle: {
                    textTransform: "uppercase",
                },
            },
            {
                title: "Libellé de l'objectif", field: "libelle", width: "25%",
                headerStyle: {
                    textTransform: "uppercase",
                },
                editComponent: (props) => (
                    <form className={classes.inputForm} noValidate autoComplete="off">
                        <TextField
                            className={classes.selectFormControl}
                            required
                            type="text"
                            size="medium"
                            error={
                                !props.value &&
                                    nameError.validateInput &&
                                    props.rowData.submitted
                                    ? nameError.error
                                    : false
                            }
                            helperText={
                                !props.value &&
                                    nameError.validateInput &&
                                    props.rowData.submitted
                                    ? nameError.helperText
                                    : ""
                            }
                            value={props.value ? props.value : ""}
                            onChange={(e) => {
                                if (nameError.validateInput) {
                                    setNameError({
                                        ...nameError,
                                        validateInput: false,
                                    });
                                }

                                props.onChange(e.target.value);
                            }}
                        />
                        <FormHelperText>Requis</FormHelperText>
                    </form>
                ),
            },
            {
                title: "Fréquence de mise à jour", field: "typeDate", width: "10%",
                headerStyle: {
                    textTransform: "uppercase",
                },
                editComponent: (props) => (
                    <>
                        <FormControl required className={classes.formControl}>
                            <Select
                                labelId="select-frequence-required-label"
                                id="select-frequence-required"
                                value={props.value}
                                defaultValue={props.value}
                                autoWidth
                                onChange={(e) => {
                                    props.onChange(e.target.value);
                                }}
                                className={classes.selectEmpty}
                            >
                                {datesList.map((frq) => (
                                    <MenuItem key={frq.typeDate} value={frq.typeDate} name={frq.typeDate}>{frq.typeDate}</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>Requis</FormHelperText>
                        </FormControl>
                    </>
                )
            },
            {
                title: "Unité", field: 'typeUnite', width: "10%",
                headerStyle: {
                    textTransform: "uppercase",
                },
                editComponent: (props) => (
                    <>
                        <FormControl required className={classes.formControl}>
                            <Select
                                labelId="select-unite-required-label"
                                id="select-unite-required"
                                value={props.value}
                                defaultValue={props.value}
                                autoWidth
                                onChange={(e) => {
                                    props.onChange(e.target.value);
                                }}
                                className={classes.selectEmpty}
                            >
                                {uniteList.map((unite) => (
                                    <MenuItem key={unite.typeUnite} value={unite.typeUnite} name={unite.typeUnite}>{unite.typeUnite}</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>Requis</FormHelperText>
                        </FormControl>
                    </>
                )
            },
            {
                title: "Commentaire libre", field: 'commentaire', width: "20%",
                editComponent: (props) => (
                    <TextField
                        id="outlined-multiline-static"
                        label="Commentaire"
                        multiline
                        className={classes.selectFormControl}
                        rows={4}
                        defaultValue={props.value}
                        variant="outlined"
                        onChange={(e) => {
                            props.onChange(e.target.value);
                        }}
                    />
                ),
                headerStyle: {
                    textTransform: "uppercase",
                },
                cellStyle: { width: '100%' }
            },
        ],
    });

    // Row add , edit or  delete
    const addNewRow = async (param) => {
        //disabled\\ 
        try {
            await serviceApi.createTypeObjectif(param);
            setRefreshTab(!refreshTab);
        } catch (error) {
            console.log(error);
        }
    }
    const updateRow = async (param) => {

        try {
            await serviceApi.updateRowTypeObjectif(param);
            setRefreshTab(!refreshTab);
        } catch (error) {
            console.log(error);
        }
    }
    const deleteRow = async (param) => {
        try {
            await serviceApi.deleteRowTypeofObjectif(param);
            setRefreshTab(!refreshTab);
            setDisplaySecondTable('visible');
        } catch (error) {
            console.log(error)
        }

    }

    const [displaySecondTable, setDisplaySecondTable] = useState("hidden");

    //scroll
    const scroll = () => {
        const section = document.querySelector('#anchorSousTab');
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    //onRow click
    const handleRowClick = (event, rowData) => {
        event.preventDefault();
        setTitleSecondTable(rowData.indic);
        if (rowData.indic !== undefined) {
            setSubtitleSecondTable(rowData.libelle);
        }
        setSendselectedRow(rowData);
        if (displaySecondTable === "hidden") {
            setDisplaySecondTable('visible');
            scroll();
        } else if (displaySecondTable === "visible") {
            scroll();
        } else {
            setDisplaySecondTable('hidden');
        }
    }
    //show second table objectif

    const [sendselectedRow, setSendselectedRow] = useState([]);
    const ShowSecondTable = (param) => {
        setSendselectedRow([param]);
    }



    return (
        <>
            <div className="corners">
                <div className="top left"></div>
                <div className="top right"></div>
                <div className="bottom right"></div>
                <div className="bottom left"></div>
                <Typography variant="h4" align='center' className={classes.titleText}>Objectifs</Typography>
            </div>            
            {refreshSelect && <Grid container alignItems="center" className={classes.root}>
                <FormControl className={classes.formControl}>
                    <InputLabel id="categorie-simple-select-autowidth-label">Thème</InputLabel>
                    <Select
                        labelId="categorie-simple-select-autowidth-label"
                        id="categorie-simple-select-autowidth"
                        value={categorie.value}
                        defaultValue={focusThemes}
                        onChange={handleChangeCategorie}
                        autoWidth
                    >
                        <MenuItem value="_all" ><em>Tout</em></MenuItem>
                        {categorie.map((cat) => (
                            <MenuItem key={cat.id} value={cat.value} name={cat.name}>{cat.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                    <InputLabel id="ssCategorie-simple-select-autowidth-label">Sous-thème </InputLabel>
                    <Select
                        labelId="ssCategorie-simple-select-autowidth-label"
                        id="ssCategorie-simple-select-autowidth"
                        value={sousCategorie.value}
                        defaultValue={focusSousThemes}
                        onChange={handleChangeSousCategorie}
                        autoWidth
                    >
                        <MenuItem value="_all"><em>Tout</em></MenuItem>
                        {sousCategorie.map((sscat) => (
                            <MenuItem key={sscat.id} value={sscat.value} name={sscat.name}>{sscat.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>}
            <div className={classes.table}>
                <MaterialTable
                    title={'DÉFINITION D\'UN TYPE D\'OBJECTIF'}
                    columns={state.columns}
                    data={entries}
                    tableRef={tableRef}
                    onRowClick={(event, rowData) => handleRowClick(event, rowData)}
                    isLoading={chargement.isLoading}
                    components={{
                        Toolbar: props => (
                            <div style={{ color:styling.colorTextHeader  }}>
                                <MTableToolbar {...props} classes={{ root: classes.toolbar }} />
                            </div>
                        ),
                    }}
                    options={{
                        rowStyle: {
                            backgroundColor: styling.tableRowBackgroundColor,
                        },
                        headerStyle: {
                            backgroundColor: styling.tableHeaderBackgroundColor,
                            color: styling.tableHeaderColor
                        },
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 30, { label: 'Tout', value: entries.length }],
                        tableLayout: "fixed", //columns dimensions
                        //actionsColumnIndex: -1, //action column o right side
                        addRowPosition: 'first', //add row on top
                        padding:'dense'
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: "{from} à {to} sur {count}",
                            labelRowsSelect: "résultats par page",
                            labelRowsPerPage: "résultats par page",
                            firstAriaLabel: "Première page",
                            firstTooltip: "Première page",
                            previousAriaLabel: "Page précédente",
                            previousTooltip: "Page précédente",
                            nextAriaLabel: "Page suivante",
                            nextTooltip: "Page suivante",
                            lastAriaLabel: "Dernière page",
                            lastTooltip: "Dernière page"
                        },
                        toolbar: {
                            addRemoveColumns: "Ajouter ou supprimer des colonnes",
                            nRowsSelected: "{0} ligne(s) sélectionnée(s)",
                            showColumnsTitle: "Afficher les colonnes",
                            showColumnsAriaLabel: "Afficher les colonnes",
                            exportTitle: "Exporter",
                            exportAriaLabel: "Exporter",
                            exportName: "Exporter en CSV",
                            searchTooltip: "Recherche",
                            searchPlaceholder: "Recherche"
                        },
                        header: {
                            actions: ''
                        },
                        body: {
                            emptyDataSourceMessage:
                                "Désolé, aucun enregistrement correspondant n'a été trouvé",
                            addTooltip: "Ajouter une ligne ",
                            deleteTooltip: "Supprimer",
                            editTooltip: "Modifier",
                            filterRow: {
                                filterTooltip: "Filtrer"
                            },
                            editRow: {
                                deleteText: "Êtes-vous sûr de vouloir supprimer cet élément ? Vous allez perdre TOUTES les données associées",
                                cancelTooltip: "Annuler",
                                saveTooltip: "Sauvegarder"
                            }
                        },
                    }}
                    style = {{
                        color: styling.tableTextColor,
                    }}
                    icons={{
                        Add: props => <AddCircleRounded {...props} style={{ fill: styling.tabButtonAddRow}} />,
                        Edit: props => <Edit  {...props} style={{ fill: "#ff9800" }} />,
                        Delete: props => <DeleteForeverIcon   {...props} style={{ fill: "#d50000" }} />,
                        Check: props => <Check   {...props} style={{ fill: "#2e7d32" }} />,
                        Clear: props => <Clear    {...props} style={{ fill: "#d50000" }} />,
                    }}
                    editable={{
                        //isEditable: rowData => roleIndicateur.canEdit(rowData), // only name(a) rows would be editable
                        isEditHidden: rowData => !rowData.access.ecriture, //only if rowData.indicId match
                        //isDeletable: rowData => rowData.sousCategorie === 'Communication', // only name(b) rows would be deletable,
                        isDeleteHidden: rowData => !rowData.access.suppression, //only if rowData.indicId match
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    newData.submitted = true;
                                    if (!newData.libelle || !newData.typeDate || !newData.typeUnite) {
                                        setNameError({
                                            error: true,
                                            label: "champs requis",
                                            helperText: "erreur",
                                            validateInput: true,
                                        });
                                        setOpenAlert(true);
                                        reject();
                                        return;
                                    }

                                    const dataUpdate = [...entries];
                                    const index = oldData.tableData.id;
                                    dataUpdate[index] = newData;
                                    setEntries([...dataUpdate]);
                                    updateRow(newData);
                                    resolve();
                                }, 1000);
                            }),
                        onRowDelete: (oldData) =>
                            new Promise((resolve) => {
                                const dataDelete = [...entries];
                                const index = oldData.tableData.id;
                                dataDelete.splice(index, 1);
                                setEntries([...dataDelete]);
                                deleteRow(oldData);
                                resolve();

                            }),
                    }}
                />

                <div id="anchorSousTab">{'\u00A0'}</div>
                <div style={{ visibility: displaySecondTable }} >
                    <Typography
                        className={classes.title}
                        variant="h6"
                        component="h3"
                        align="center"

                    >
                        {titleSecondTable}
                    </Typography>
                    {subtitleSecondTable !== "" ? 
                        <Typography
                            className={classes.subtitle}
                            variant="subtitle1"
                            component="h4"
                            align="center"

                        >
                            {subtitleSecondTable}
                        </Typography> : null
                    }
                    
                </div>
            </div>
            {/* second table */}
            <div style={{ visibility: displaySecondTable }} >
                <ObjectifTable ShowSecondTable={sendselectedRow} />
            </div>
            <Snackbar 
                open={openAlert} 
                autoHideDuration={6000} 
                onClose={handleCloseAlert}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert variant="filled" severity="error">
                    Veuillez vérifier votre saisie.
                </Alert>
            </Snackbar>
        </>

    )
}

export default Objectifs;