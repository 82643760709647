import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable, {MTableToolbar, MTableEditField } from 'material-table';
import axios from "axios";
import CategorieApi from '../services/CategorieApi';
import serviceApi from '../services/ServiceApi';
import * as All from '../config';
import { TextField,
         Select,
         FormControl,
         FormHelperText,
         MenuItem
        } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';


import AddCircleRounded from '@material-ui/icons/AddCircleRounded';
import Edit from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
//styling
import * as styling from '../services/styling';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        marginTop: '25vh',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    toolbar:{
        backgroundColor: styling.backGroundHeader,        
    },
}));

const ManageableListsSousTab = ({selectedRow}) => {
    const classes = useStyles();

    const API = All.API_URL;
    const [apiResponse, setApiResponse] = useState([]);
    const [tableProperty, setTableProperty] = useState([]);
    const [refreshTab, setRefreshTab] = useState(false);
    const [liste, setListe] = useState({
        columns: []
    });
    const [categorie, setCategorie] = useState([]);

    const [listeTypeUnite, setListeTypeUnite] = useState({
        columns: [
          { title: 'Libellé', field: 'libelleUnite' },
          { title: 'Float (Numérique)', field: 'isFloat', type: "boolean" },
          { title: 'Texte', field: 'isText', type: "boolean"},
          { title: 'Booléen', field: 'isBoolean', type: "boolean"},
        ]
    });

    //error
    const [openAlert, setOpenAlert] = useState(false);
    const handleCloseAlert = () => {
        setOpenAlert(false);
    };
    const [openAlertSameValue, setOpenAlertSameValue] = useState(false);
    const handleCloseAlertSameValue = () => {
        setOpenAlertSameValue(false);
    };

    useEffect(() => {
        const getAllCategorie = async () => {
            try {
                const responseCategorie = await CategorieApi.findAllCategorie();
                setCategorie(responseCategorie);
            } catch(error){
                console.log(error);
            }
        }
        getAllCategorie();
    }, [refreshTab]);

    const [allSousCategorie, setAllSousCategorie] = useState([]);
    useEffect(() => {
        const getAllSousCategorie = async () => {
            const responseSousCategorie = await serviceApi.findAllSousCategorie();
            setAllSousCategorie(responseSousCategorie);
        };
        getAllSousCategorie();
    },[refreshTab])
    useEffect(() => {
        const getRowDataApi = async (selectedRow) => {
            try {
                await axios.get(API + selectedRow.id)
                           .then(response => {
                                const data = response.data['hydra:member'];
                                setApiResponse(data);
                            });
                if(selectedRow.id !== 'type_unites'){
                    await axios.post(All.GETSCHEMATABLE, {table: selectedRow.id})
                    .then((response) => {
                        setTableProperty(response.data['data']);
                     });
                }
              } catch(error) {
                  console.log(error);
              }
        }
        if(selectedRow.id !== undefined){
            getRowDataApi(selectedRow);
        }
    }, [selectedRow, refreshTab]);

    useEffect(() => {
        let columns = [];
        const regexId = RegExp('^id');
        const regexCommentaire = RegExp('^commentaire');
        for(let i = 0; i < tableProperty.length; i++){
            if(regexId.test(tableProperty[i]) === false){ // Exclu la colonne id
                if(regexCommentaire.test(tableProperty[i])) {
                    columns.push({ title: 'Commentaires', field: tableProperty[i],
                        editComponent:(props) => (
                            <TextField
                                id="outlined-multiline-static"
                                label="Commentaire"
                                multiline
                                className={classes.selectFormControl}
                                rows={4}
                                defaultValue={props.value}
                                variant="outlined"
                                onChange={(e)=>{
                                    props.onChange(e.target.value);
                                }}
                            />
                        ),
                    });
                } else {
                    switch(tableProperty[i]){
                        case "nomResponsable":
                            columns.push({title: 'Nom du responsable', field: tableProperty[i],
                            editComponent:(props) => (
                                <TextField
                                    type="text"
                                    required
                                    id="outlined-nomResponsable-static"
                                    label="Libellé"
                                    defaultValue={props.value}
                                    variant="outlined"
                                    onChange={(e) => { props.onChange(e.target.value);  }}
                                />
                            ),                        
                        });
                            break;
                        case "libelleSousCategorie":
                            columns.push({
                                title: 'Libellé du sous-thème', field: tableProperty[i],
                                editComponent:(props) => (
                                    <TextField
                                        type="text"
                                        required
                                        id="outlined-libelleSousCategorie-static"
                                        label="Libellé"
                                        defaultValue={props.value}
                                        variant="outlined"
                                        onChange={(e) => { props.onChange(e.target.value);  }}
                                        helperText="champs unique"
                                    />
                                ),
                            }
                            );
                            break;
                        case "libelleCategorie":
                            columns.push({title: 'Libellé du thème', field: tableProperty[i],                        
                            editComponent:(props) => (
                                <TextField
                                    type="text"
                                    required
                                    id="outlined-libelleCategorie-static"
                                    label="Libellé"
                                    defaultValue={props.value}
                                    variant="outlined"
                                    onChange={(e) => { props.onChange(e.target.value);  }}
                                    helperText="champs unique"
                                />
                            ),
                        });
                            break;
                        case "libelle":
                            columns.push({title: 'Libellé', field: tableProperty[i],
                            editComponent:(props) => (
                                <TextField
                                    type="text"
                                    required
                                    id="outlined-libelle-static"
                                    label="Libellé"
                                    defaultValue={props.value}
                                    variant="outlined"
                                    onChange={(e) => { props.onChange(e.target.value);  }}
                                />
                            ),    
                        });
                            break;
                        default:
                            columns.push({title: tableProperty[i], field: tableProperty[i]});
                            break; 
                    }
                }
            }
        } 

        if(selectedRow.name === "Sous-thème"){
            columns.push({ title: 'Catégorie associée', field: 'idCategorie.libelleCategorie', 
                editComponent: (props) => (
                    <>
                        <FormControl required>
                            <Select
                                labelId="select-libelleCategorie-required-label"
                                id="select-libelleCategorie-required"
                                value={props.value ? props.value : ""}
                                defaultValue={props.value}
                                autoWidth
                                onChange={(e) => {
                                    props.onChange(e.target.value);
                                }}
                                className={classes.selectEmpty}
                            >
                                {categorie.map((cat) => (
                                    <MenuItem key={cat.id} value={cat.libelleCategorie} name={cat.libelleCategorie}>{cat.libelleCategorie}</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>Requis</FormHelperText>
                        </FormControl>
                    </>
                )
            });
        }
        setListe({columns: columns});
    }, [tableProperty]);

    const updateRowTypeUnite = async (param) => {
            try {
                await axios.put(API + selectedRow.id + "/" + param.id, param);
                setRefreshTab(!refreshTab);
            } catch(error){
                console.log(error.response);
            }
    }

    const getUriCategorie = (libelle) => {
        let catId;
        categorie.forEach(element => {
            if(element.libelleCategorie === libelle){
                catId =  "\/api\/categorie_indicateurs\/" + element.id;
            }            
        })
        return catId
    }

    const updateRow = async (param) => {
        let IdApi;
        let uri;
        if(param.id !== undefined) {
            IdApi = param.id;
        } else if(param.idtypeFlag !== undefined) {
            IdApi = param.idtypeFlag ;
        } else if(param.idResponsable !== undefined) {
            IdApi = param.idResponsable;
        }
        if (param.idCategorie !== undefined) {
            uri = getUriCategorie(param.idCategorie.libelleCategorie);
            param.idCategorie =  uri;
        }
        
        try {
            await axios.put(API + selectedRow.id + "/" + IdApi, param);
            setRefreshTab(!refreshTab);
        } catch(error){
            console.log(error.response);
            setOpenAlert(true);
        }
    }

    const addNewRowTypeUnite = async (param) => {
        let data = [];

        for(const item in param){
            data.push(param[item]);
        }

        let count = 0
        for(let i = 0; i < data.length; i++){
            if(data[i] === true){
                count++;
            }
        }

        if(param !== null){
            if(count <= 1){
                try {
                    await axios.post(API + selectedRow.id, param);
                    setRefreshTab(!refreshTab);
                } catch (error) {
                    console.log(error);
                }
            } else {
                console.log("la requête ne peut pas être executée car plusieurs checkbox ont été selectionnées");
            }
        } 
    }

    const addNewRow = async (param) => {
        let uri;
        
        if(param.idCategorie !== undefined) {
            uri = getUriCategorie(param.idCategorie.libelleCategorie);
            param.idCategorie =  uri;
            try {
                await axios.post(API + selectedRow.id, param);
                
            } catch (error) {
                console.log(error);
            }
            setRefreshTab(!refreshTab);
        } else {
            try {
                await axios.post(API + selectedRow.id, param);
            } catch (error) {
                console.log(error);
            }
            setRefreshTab(!refreshTab);
        }
    }

    const deleteRow = async (param) => {
        let IdApi;
        if (param.id !== undefined) {
            IdApi = param.id;
        } else if (param.idtypeFlag !== undefined) {
            IdApi = param.idtypeFlag;
        } else if (param.idResponsable !== undefined) {
            IdApi = param.idResponsable;
        }

        try {
            await axios.delete(API + selectedRow.id + '/' + IdApi);
            setRefreshTab(!refreshTab);
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <MaterialTable
                validationError={true}
                title={`Modification de la liste "${selectedRow.name}"`}
                columns={selectedRow.id === "type_unites" ? listeTypeUnite.columns : liste.columns}
                data={apiResponse}
                components={{
                    Toolbar: props => (
                        <div style={{ color: styling.colorTextHeader }}>
                            <MTableToolbar {...props} classes={{ root: classes.toolbar }} />
                        </div>
                    ),
                }}
                options={{
                    rowStyle: {
                        backgroundColor: styling.tableRowBackgroundColor,
                    },
                    headerStyle: {
                        backgroundColor: styling.tableHeaderBackgroundColor,
                        color: styling.tableHeaderColor
                    },
                    pageSize: 10,
                    pageSizeOptions: [10, 15, 20,{ label: 'Tout', value: apiResponse.length }],
                    tableLayout: "fixed", //columns dimensions
                    //actionsColumnIndex: -1, //action column o right side
                    addRowPosition: 'first', //add row on top
                    tableLayout: "fixed", //columns dimensions
                    addRowPosition: 'first' //add row on top
                }}
                localization={{
                    pagination: {
                        labelDisplayedRows: "{from} à {to} sur {count}",
                        labelRowsSelect: "résultats par page",
                        labelRowsPerPage: "résultats par page",
                        firstAriaLabel: "Première page",
                        firstTooltip: "Première page",
                        previousAriaLabel: "Page précédente",
                        previousTooltip: "Page précédente",
                        nextAriaLabel: "Page suivante",
                        nextTooltip: "Page suivante",
                        lastAriaLabel: "Dernière page",
                        lastTooltip: "Dernière page"
                    },
                    toolbar: {
                        addRemoveColumns: "Ajouter ou supprimer des colonnes",
                        nRowsSelected: "{0} ligne(s) sélectionnée(s)",
                        showColumnsTitle: "Afficher les colonnes",
                        showColumnsAriaLabel: "Afficher les colonnes",
                        exportTitle: "Exporter",
                        exportAriaLabel: "Exporter",
                        exportName: "Exporter en CSV",
                        searchTooltip: "Recherche",
                        searchPlaceholder: "Recherche"
                    },
                    header: {
                        actions: ''
                    },
                    body: {
                        emptyDataSourceMessage:
                            "Désolé, aucun enregistrement correspondant n'a été trouvé",
                        addTooltip: "Ajouter une ligne ",
                        deleteTooltip: "Supprimer",
                        editTooltip: "Modifier",
                        filterRow: {
                            filterTooltip: "Filtrer"
                        },
                        editRow: {
                            deleteText: "Êtes-vous sûr de vouloir supprimer cet élément ? Vous allez perdre TOUTES les données associées",
                            cancelTooltip: "Annuler",
                            saveTooltip: "Sauvegarder"
                        }
                    },
                }}
                style = {{
                    color: styling.tableTextColor,
                }}
                icons={{
                    Add: props => <AddCircleRounded {...props} style={{ fill: styling.tabButtonAddRow}} />,
                    Edit: props => <Edit  {...props} style={{ fill: "#ff9800" }} />,
                    Delete: props => <DeleteForeverIcon   {...props} style={{ fill: "#d50000" }} />,
                    Check: props => <Check   {...props} style={{ fill: "#2e7d32" }} />,
                    Clear: props => <Clear    {...props} style={{ fill: "#d50000" }} />,
                }}
                editable={{
                    onRowAdd: (newData) =>
                        new Promise((resolve, reject) => {
                            
                            if (Object.keys(newData).length === 0) {
                                setOpenAlert(true);
                                reject();
                                return;
                            } else {
                                switch (selectedRow.id) {
                                    case "type_unites":
                                        if (newData.libelleUnite && Object.keys(newData).length > 1) {
                                            let count = 0;
                                            Object.values(newData).forEach(val => {
                                                if (val === true) {
                                                    count++;
                                                }
                                            });
                                            if (newData.libelleUnite !== "" && count === 1) {
                                                addNewRowTypeUnite(newData);
                                                resolve();
                                            } else {
                                                setOpenAlert(true);
                                                reject();
                                                return
                                            }
                                        } else {
                                            setOpenAlert(true);
                                            reject();
                                            return
                                        }
                                        break;
                                    case "type_dates":
                                        if (newData.libelle && newData.libelle !== "") {
                                            addNewRow(newData);
                                            resolve();
                                        } else {
                                            setOpenAlert(true);
                                            reject();
                                            return
                                        }
                                        break;
                                    case "type_flags":
                                        if (newData.libelle && newData.libelle !== "") {
                                            addNewRow(newData);
                                            resolve();
                                        } else {
                                            setOpenAlert(true);
                                            reject();
                                            return
                                        }
                                        break;
                                    case "categorie_indicateurs":
                                        if (newData.libelleCategorie && newData.libelleCategorie !== "") {
                                            //Check if the label is already used
                                            if (categorie.length > 0) {
                                                let countError = 0;
                                                categorie.forEach(value => {
                                                    if (value.libelleCategorie.trim().toLowerCase() === newData.libelleCategorie.trim().toLowerCase()) {
                                                        setOpenAlertSameValue(true);
                                                        countError++;                                                        
                                                    }    
                                                })
                                                if (countError === 0) {
                                                    addNewRow(newData);
                                                    resolve();
                                                }else{
                                                    setOpenAlert(true);
                                                    reject();
                                                    return
                                                }                              
                                            } else {                                                
                                                addNewRow(newData);
                                                resolve();
                                            }
                                        } else {                                            
                                            setOpenAlert(true);
                                            reject();
                                            return
                                        }
                                        break;
                                    case "sous_categorie_indicateurs":
                                        if (newData.libelleSousCategorie && newData.libelleSousCategorie !== "" && newData.idCategorie && newData.idCategorie.libelleCategorie !== "") {
                                            if(allSousCategorie.length > 0) {
                                                let countError = 0;
                                                allSousCategorie.forEach(ssCat => {                                                   
                                                    if((ssCat.libelleSousCategorie.trim().toLowerCase() === newData.libelleSousCategorie.trim().toLowerCase()) && (ssCat.idCategorie.libelleCategorie.trim().toLowerCase() === newData.idCategorie.libelleCategorie.trim().toLowerCase()) ){        
                                                        setOpenAlertSameValue(true);
                                                        countError++;
                                                    }                                                
                                                })
                                                if (countError === 0) {
                                                    addNewRow(newData);
                                                    resolve();
                                                }else{
                                                    setOpenAlert(true);
                                                    reject();
                                                    return
                                                }    
                                            }else {                                                
                                                addNewRow(newData);
                                                resolve();
                                            }
                                        } else {
                                            setOpenAlert(true);
                                            reject();
                                            return
                                        }
                                        break;
                                    case "responsables":
                                        if (newData.nomResponsable && newData.nomResponsable !== "" && Object.keys(newData).length >= 1) {
                                            addNewRow(newData);
                                            resolve();
                                        } else {
                                            setOpenAlert(true);
                                            reject();
                                            return
                                        }
                                        break;
                                }
                            }
                        }), 


                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                                const dataUpdate = [...apiResponse];
                                const index = oldData.tableData.id;
                                dataUpdate[index] = newData;
                                if (Object.keys(newData).length === 0) {
                                    setOpenAlert(true);
                                    reject();
                                    return;
                                } else {
                                    switch (selectedRow.id) {
                                        case "type_unites":
                                            if (newData.libelleUnite && Object.keys(newData).length > 1) {
                                                let count = 0;
                                                Object.values(newData).forEach(val => {
                                                    if (val === true) {
                                                        count++;
                                                    }
                                                });
                                                if (newData.libelleUnite !== "" && count === 1) {
                                                    updateRowTypeUnite(newData);
                                                    resolve();
                                                } else {
                                                    setOpenAlert(true);
                                                    reject();
                                                    return
                                                }
                                            } else {
                                                setOpenAlert(true);
                                                reject();
                                                return
                                            }
                                            break;
                                        case "type_dates":
                                            if (newData.libelle && newData.libelle !== "") {
                                                updateRow(newData);
                                                resolve();
                                            } else {
                                                setOpenAlert(true);
                                                reject();
                                                return
                                            }
                                            break;
                                        case "type_flags":
                                            if (newData.libelle && newData.libelle !== "") {
                                                updateRow(newData);
                                                resolve();
                                            } else {
                                                setOpenAlert(true);
                                                reject();
                                                return
                                            }
                                            break;
                                        case "categorie_indicateurs":
                                            if (newData.libelleCategorie && newData.libelleCategorie !== "") {
                                                //Check if the label is already used
                                                if (categorie.length > 0) {
                                                    let countError = 0;
                                                    categorie.forEach(value => {
                                                        if (value.libelleCategorie.trim().toLowerCase() === newData.libelleCategorie.trim().toLowerCase()) {
                                                            setOpenAlertSameValue(true);
                                                            countError++;                                                        
                                                        }    
                                                    })
                                                    if (countError === 0) {
                                                        updateRow(newData);
                                                        resolve();
                                                    }else{
                                                        setOpenAlert(true);
                                                        reject();
                                                        return
                                                    }                              
                                                } else {                                                
                                                    updateRow(newData);
                                                    resolve();
                                                }
                                            } else {                                            
                                                setOpenAlert(true);
                                                reject();
                                                return
                                            }
                                            break;
                                    case "sous_categorie_indicateurs":
                                        if (newData.libelleSousCategorie && newData.libelleSousCategorie !== "" && newData.idCategorie && newData.idCategorie.libelleCategorie !== "") {
                                            if(allSousCategorie.length > 0) {
                                                let countError = 0;
                                                allSousCategorie.forEach(ssCat => {                                                   
                                                    if((ssCat.libelleSousCategorie.trim().toLowerCase() === newData.libelleSousCategorie.trim().toLowerCase()) && (ssCat.idCategorie.libelleCategorie.trim().toLowerCase() === newData.idCategorie.libelleCategorie.trim().toLowerCase()) ){        
                                                        setOpenAlertSameValue(true);
                                                        countError++;
                                                    }                                                
                                                })
                                                if (countError === 0) {
                                                    updateRow(newData);
                                                    resolve();
                                                }else{
                                                    setOpenAlert(true);
                                                    reject();
                                                    return
                                                }    
                                            }else {                                                
                                                updateRow(newData);
                                                resolve();
                                            }
                                        } else {
                                            setOpenAlert(true);
                                            reject();
                                            return
                                        }
                                        break;
                                        case "responsables":
                                            if (newData.nomResponsable && newData.nomResponsable !== "" && Object.keys(newData).length >= 1) {
                                                updateRow(newData);
                                                resolve();
                                            } else {
                                                setOpenAlert(true);
                                                reject();
                                                return
                                            }
                                            break;
                                    }
                                }
                        }),
                    onRowDelete: (oldData) =>
                        new Promise((resolve) => {
                            const dataDelete = [...apiResponse];
                            const index = oldData.tableData.id;
                            dataDelete.splice(index, 1);
                            deleteRow(oldData);
                            resolve();
                        }),
                }}
            />
            <Snackbar 
                open={openAlert} 
                autoHideDuration={6000} 
                onClose={handleCloseAlert}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert variant="filled" severity="error">
                    Veuillez vérifier votre saisie.
                </Alert>
            </Snackbar>
            <Snackbar 
                open={openAlertSameValue} 
                autoHideDuration={6000} 
                onClose={handleCloseAlertSameValue}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert variant="filled" severity="error">
                    Ce libellé est déjà présent dans les catégories.
                </Alert>
            </Snackbar>
        </>
    )
}

export default ManageableListsSousTab