//date fns
import { eachMonthOfInterval } from 'date-fns'
import { formatISO } from 'date-fns'
import endOfYear from 'date-fns/endOfYear'
import format from 'date-fns/format'
// query
import serviceApi from '../services/ServiceApi';

function DateIndicateurs(param) {
    //id type objectifs
    const typeIndicateursId = param.id;

    //dates

    const year = new Date().getFullYear();
    const firstDayOfYear = new Date(year, 0, 1); 
    const firstDayOfYearFormat = format(firstDayOfYear, 'dd-MM-yyyy');
    const endDayOfYear = endOfYear(new Date());
    const months = eachMonthOfInterval({
        start: firstDayOfYear,
        end: endDayOfYear
    });

    //insert
    const insertIndicateurs = async (param) => {
        try{
           const insertionIndic = await serviceApi.createIndicateurs(param) ;
        }catch (err) {
            console.log(err)
        }
        
    }

    //get libelle
    if (param.typeDate.libelle === "ANNUEL") {
        let objAnnuel = {
            idTypeIndicateur: {
                id: typeIndicateursId
            },
            date: formatISO(firstDayOfYear)

        }
        insertIndicateurs(objAnnuel);

    } else if (param.typeDate.libelle === "MENSUEL") {
        let monthArray = [];
        for (let i = 0; i < months.length; i++) {
            monthArray.push(
                {
                    idTypeIndicateur: {
                        id: typeIndicateursId
                    },
                    date: formatISO(months[i])
                }
            )
        }
        monthArray.forEach(month => {
            insertIndicateurs(month)
        });
    } else if (param.typeDate.libelle === "TRIMESTRIEL") {
        let trimArray = [];
        let trimestre = [0, 3, 6, 9];
        for (let j = 0; j < trimestre.length; j++) {
            trimArray.push(
                {
                    idTypeIndicateur: {
                        id: typeIndicateursId
                    },
                    date: formatISO(months[trimestre[j]])
                }
            )
        }
        trimArray.forEach(element => {
            insertIndicateurs(element);
        });
    }


    return
}



export default {
    DateIndicateurs
}